/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-17 15:58:18
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 16:57:44
 */
import HandleData from '@/service/Activity'
import { createNamespacedHelpers, mapGetters as mapRootGetters } from 'vuex'
const { mapGetters } = createNamespacedHelpers('App/AcvicityAdd')
export default {
  name: 'ActivityForm',
  data () {
    return {
      form: {
        name: '',
        media_platform: '',
        data_type: '',
        monitor_platform: '',
        report_type: '',
        origin_url: ''
      }
    }
  },
  computed: {
    ...mapRootGetters([
      'enum_map'
    ]),
    ...mapGetters([
      'rules'
    ])
  },
  methods: {
    async init () {
      const id = this.$route.params.id
      const data = await this.$wPost('/app/activity/get.do', { id })
      console.log(data)
      for (const i in data) {
        this.form[i] = data[i]
      }
      this.monitorPlatformChange(this.form.monitor_platform)
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.addSubmit()
          } else {
            this.editSubmit()
          }
        } else {
          this.$refs.submitButton.updatedLoading(false)
        }
      })
    },
    async addSubmit () {
      const payload = new HandleData(this.form).appAdd()
      const data = await this.$wPost('/app/activity/add.do', payload)
      if (data !== false) {
        // this.$wToast.success('成功')
        this.$emit('add', data)
      }
      this.$refs.submitButton.updatedLoading(false)
    },
    async editSubmit () {
      const payload = new HandleData(this.form).appEdit()
      const data = await this.$wPost('/app/activity/update.do', payload)
      if (data !== false) {
        // this.$wToast.success('成功')
        this.$emit('add', data)
      }
      this.$refs.submitButton.updatedLoading(false)
    },
    monitorPlatformChange (val) {
      if (val === 4) {
        const arr = ['report_type', 'origin_url']
        arr.forEach(e => {
          this.$set(this.form, e, '')
        })
      }
      this.$emit('monitor-change', val)
    },
    cancel () {
      history.go(-1)
    }
  },
  props: {
    isAdd: {
      type: Boolean,
      default: true
    }
  },
  async beforeMount () {
    if (!this.isAdd) {
      await this.init()
    } else {
      const id = this.enum_map.report_type[0].id || ''
      this.$set(this.form, 'report_type', id)
    }
  }
}
