/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-22 20:54:55
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-23 14:36:43
 */
class HandleData {
  constructor (payload) {
    this.payload = payload
  }

  appAdd () {
    const newPayload = {
      name: this.payload.name,
      media_platform: this.payload.media_platform,
      data_type: this.payload.data_type,
      monitor_platform: this.payload.monitor_platform
    }

    if (this.payload.monitor_platform !== 4) {
      newPayload.report_type = this.payload.report_type
      newPayload.origin_url = this.payload.origin_url
    }

    return newPayload
  }

  appEdit () {
    const newPayload = {
      id: this.payload.id,
      name: this.payload.name,
      media_platform: this.payload.media_platform,
      data_type: this.payload.data_type,
      monitor_platform: this.payload.monitor_platform
    }

    if (this.payload.monitor_platform !== 4) {
      newPayload.report_type = this.payload.report_type
      newPayload.origin_url = this.payload.origin_url
    }

    return newPayload
  }

  updateStatus () {
    const newPayload = {
      id: this.payload.id
    }
    if (this.payload.status === 1) {
      newPayload.status = 2
    } else {
      newPayload.status = 1
    }
    return newPayload
  }

  appTestAdd () {
    const newPayload = {
      activity_id: Number(this.payload.activity_id),
      divice_type: this.payload.divice_type,
      report_conver: this.payload.report_conver,
      divice_id: this.payload.divice_id,
      ip: this.payload.ip
    }
    return newPayload
  }
}

export default HandleData
